<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>
        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.startDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.endDate | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                <b-dropdown-item variant="flat-primary" @click="editGain(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteGain(data.item.id)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                </b-dropdown-item>
            </b-dropdown>
        </template>
    </b-table>
    <!--Open Add Gain-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Kazanım Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kazanım Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addGainRequest.code" />
                </b-form-group>
                <b-form-group label="Kazanım Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addGainRequest.text" />
                </b-form-group>
                <b-form-group label="Kazanım Videosu?" label-for="vue-select">
                    <v-select id="vue-select"  v-if="!addGainRequest.otherVideoGroup" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.id" :options="videos" v-model="addGainRequest.videoId" />
                </b-form-group>
                 <b-form-group label=" " label-for="vue-select">
                    <b-form-checkbox   name="checkbox-13" id="checkbox-13" label="othervidos"  value="true"  
                      v-model="addGainRequest.otherVideoGroup" >
                        Kazanım Diğer Videolarda
                    </b-form-checkbox>
                  
                </b-form-group>

               
                <span v-if="addGainRequest.otherVideoGroup">
                 <b-form-group label="Sınıf/Ders" label-for="">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-form-group>
                <b-form-group label="Alt Ders" label-for="">
                   
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>

                <b-form-group label="Konu" label-for="">
                  
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>

                <b-form-group label="Video Grup" label-for="">
  
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>
                <b-form-group label="Kazanım Videosu?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.id" :options="videos2" v-model="addGainRequest.videoId" />
                </b-form-group>
                </span>
                
                <b-form-group label="Başlama Süresi ?" label-for="name">
                    <cleave id="time" v-model="addGainRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addGain">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Gain-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Kazanım Düzenle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Kazanım Kodu" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editGainRequest.code" />
                </b-form-group>
                <b-form-group label="Kazanım Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editGainRequest.text" />
                </b-form-group>

              

                <b-form-group v-if="!editGainRequest.otherVideoGroup" label="Kazanım Videosu?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.id" :options="videos" v-model="editGainRequest.videoId" />
                </b-form-group>
                
                <b-form-group label=" " label-for="vue-select">
                    <b-form-checkbox    name="checkbox-132" id="checkbox-132" label="othervidos"  value="true"  
                    v-model="editGainRequest.otherVideoGroup" >
                        Kazanım Diğer Videolarda
                    </b-form-checkbox>
                  
                </b-form-group>

               
                <span v-if="editGainRequest.otherVideoGroup">
                 <b-form-group label="Sınıf/Ders" label-for="">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-form-group>
                <b-form-group label="Alt Ders" label-for="">
                   
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>

                <b-form-group label="Konu" label-for="">
                  
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>

                <b-form-group label="Video Grup" label-for="">
  
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-form-group>
                <b-form-group label="Kazanım Videosu?" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :reduce="(video) => video.id" :options="videos2" v-model="editGainRequest.videoId" />
                </b-form-group>
                </span>


                <b-form-group label="Başlama Süresi ?" label-for="name">
                    <cleave id="time" v-model="editGainRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editGainRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
          
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateGain">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
export default {
    components: {
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        Cleave,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            selectedLesson:'',
            selectedTopic:'',
            selectedVideoGroup:'',
            selectedChildLesson:'',
            lessons:[],
            childLessons:[],
            topics:[],
            videoGroups:[],
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 4
            },
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Kazanım Kodu' },
                { key: 'text', label: 'Kazanım Adı' },
                { key: 'actions', label: 'İşlemler' },
            ],
            videos: [],
            videos2: [],
            users: [],
            addGainPanel: false,
            editGainPanel: false,
            addGainRequest: {
                otherVideoGroup:false,
                videoGroupId: 0,
                videoId: 0,
                code: '',
                name: '',
                startTime: '00:00:00',
            },
            editGainRequest: {
                otherVideoGroup:false,
                gainId: 0,
                videoId: 0,
                code: '',
                name: '',
                startTime: '00:00:00',
                isActive: false
            },
            time: {
                time: true,
                timePattern: ['h', 'm', 's'],
            },
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addGainPanel = true;
        });
    },
    created() {
        this.getData();
        this.getVideo();
        this.getLesson();
    },
    watch:{
            selectedLesson: function (val) {
            this.getChildLesson();
            this.childClass = [];
            this.filterRequest.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getVideos();
        }
        
    },
    methods: {
        
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },
        async getVideos() {
            var videos2 = await this.$http.get("Teacher/Video/" + this.selectedVideoGroup);
            this.videos2 = videos2.data.data;
        },
        
        async getData() {
            var users = await this.$http.get("Teacher/Gains/" + this.$route.params.videoGroupId);
            this.users = users.data.data;
        },
        async getVideo() {
            var videos = await this.$http.get("Teacher/Video/" + this.$route.params.videoGroupId);
            this.videos = videos.data.data;
        },
        
        editGain(data) {
         
            data.videoid=data.video != null ? data.video.id : null;
            //console.log(this.videos.filter(x=>x.id==data.videoid).length);
            if(this.videos.filter(x=>x.id==data.videoid).length<1  &&   data.videoid!=null){
                this.videos.push(data.video);
            }
       
            this.editGainRequest = {
                otherVideoGroup:false,
                gainId: data.id,
                code: data.code,
                text: data.text,
                startTime: data.startTime,
                videoId: data.videoid,
                isActive: data.isActive
            };

            this.editGainPanel = true;
        },
        addGain() {
        
            this.addGainRequest.videoGroupId = this.$route.params.videoGroupId;
            var request = this.addGainRequest;
            this.$http.post("Teacher/AddGains", request).then((data) => {
                    this.getData();
                    this.addGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kazanım Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateGain() {
            var request = this.editGainRequest;
            this.$http.put("Teacher/UpdateGains", request).then((data) => {
                    this.getData();
                    this.editGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kazanım Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteGain(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteGains/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Kazanım Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
